import React from "react"
import Layout from "../components/layout"

const GameRoomPage = () => {
  
  return (<Layout>
  </Layout>);
  
}

export default GameRoomPage
